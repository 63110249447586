import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Featurette = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "businessphoto1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "businessphoto2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThree: file(relativePath: { eq: "handshake_5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="home-intro-section text-left">
      <h2 style={{ marginBottom: "7rem", textAlign: "center" }}>
        Why Work With ABFS?
      </h2>

      {/* Featurette Section */}
      <div className="container mb-5">
        <div className="row featurette">
          <div className="col-md-7 ">
            <h2 className="featurette-heading">Excellent Customer Service</h2>
            <p className="lead">
              Our professional staff is committed to offering personalized
              assistance and strategies to get your business started by helping
              you create and meet your goals. We pride ourselves on the quality
              of our work. We'll stay in touch with you following our in-person
              meetings, ensuring your business remains on the right path.
            </p>
          </div>
          <div className="col-md-5">
            <Img
              fluid={data.imageTwo.childImageSharp.fluid}
              className="shadow"
              alt="customer service agent"
            />
          </div>
        </div>

        <hr className="featurette-divider" />
        <div className="row featurette mb-5">
          <div className="col-md-7 order-md-2 ">
            <h2 className="featurette-heading">Top Quality Services</h2>
            <p className="lead">
              While we offer a broad range of services, you can still expect to
              receive excellent customer service from our team. Our qualified
              personnel will leave you feeling satisfied.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <Img
              fluid={data.imageThree.childImageSharp.fluid}
              className="shadow"
              alt="handshaking"
            />
          </div>
        </div>

        <hr className="featurette-divider" />
        <div className="row featurette mb-5">
          <div className="col-md-7 ">
            <h2 className="featurette-heading">
              Your Satisfaction is Our Goal!
            </h2>
            <p className="lead">
              Every client is like family. We understand that you have options
              and we value your decision to trust us with your business and
              individual needs. At A. Bernard Financial Services you are not a
              number, you're a top priority!
            </p>
          </div>
          <div className="col-md-5">
            <Img
              fluid={data.imageOne.childImageSharp.fluid}
              classNameName="shadow"
              alt="office staff"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Featurette
