import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import handshake_5 from "../images/handshake_5.jpg"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "ceo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "manager.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThree: file(relativePath: { eq: "avatar.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),url(${handshake_5})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "300px",
        }}
      >
        <div className="p-5">
          <h1
            style={{ marginTop: "4rem", textAlign: "center", color: `white` }}
          >
            About Us
          </h1>
        </div>
      </div>
      <section className="text-center about-section" id="about">
        <div className="container">
          <div className="mb-5">
            {" "}
            <div className="">
              <div className="row ">
                <div className="col-log-4">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent">
                      <li className="breadcrumb-item">
                        <Link className="text-decoration-none text-dark" to="/">
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        About
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="lead text-left">
              In Business since 1984. A. Bernard Financial Services is a
              privately owned accounting firm based in Miami Florida. ABFS was
              founded by Anthony Bernard, President, whose primary goal is
              assisting small businesses and individuals with their financial
              needs. By building and supporting other businesses, our company
              has grown from small beginnings to now a trusted and renowned name
              in the community and across state lines. Our main objective is to
              provide business owners and entrepreneurs with the tools necessary
              to succeed. With the help and support of our expert team members
              along the way, we're sure to help you and your company reach new
              heights.
            </p>
          </div>

          <div> </div>
          <div style={{ marginTop: "5rem", paddingBottom: "3rem" }}>
            <h1>Meet Our Team</h1>
            <hr className="mb-5 w-25"></hr>
          </div>
          <div className="row staff-images">
            <div className="col-lg-4 col-md-8 col-sm-8 mx-auto">
              <div className="mx-auto mb-5 mb-lg-0 w-50">
                <Img
                  fluid={data.imageOne.childImageSharp.fluid}
                  fadeIn
                  className="shadow rounded-circle mb-3"
                  alt="CEO"
                />
                <h5>Anthony Bernard</h5>
                <p className="text-muted mb-2">CEO</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-sm-8 mx-auto">
              <div className="mx-auto mb-5 mb-lg-0 w-50">
                <Img
                  fluid={data.imageTwo.childImageSharp.fluid}
                  fadeIn
                  className="shadow rounded-circle mb-3"
                  alt="Office Staff"
                />
                <h5>Tessa Harris</h5>
                <p className="text-muted mb-2">Manager</p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-8 col-sm-8 mx-auto">
            <div className="mx-auto mb-5 mb-lg-0 w-50">
               <Img
                fluid={data.imageThree.childImageSharp.fluid}
                fadeIn
                className="shadow rounded-circle mb-3"
                alt="Office Staff"
              />
              <h5>Carla Bernard</h5>
              <p className="text-muted mb-2">Manager</p>
            </div>
          </div>  */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default About
