import React from "react"
import Services from "../components/services"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = () => (
  <Layout>
    <SEO title="A. Bernard Financial Services" />
    <Services />
  </Layout>
)

export default ServicesPage
