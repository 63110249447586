import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Page Not found" />
      <div className="container text-center my-5">
        <h1 className="mt-3">404 - Page Not Found</h1>
        <h6 className="text-muted mt-3">
          The requested URL was not found on this server.
        </h6>
        <Link className="btn btn-dark mt-3" to="/">
          Go to Homepage
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
