import React from "react"
import GoogleMap from "./google-map"
import Form from "./form"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import contactimage from "../images/contactimage.jpg"

const Contact = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),url(${contactimage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "300px",
        }}
      >
        <div className="p-5">
          <h1
            style={{ marginTop: "4rem", textAlign: "center", color: `white` }}
          >
            Contact Us
          </h1>
        </div>
      </div>

      <div className="container">
        <div className="row ">
          <div className="col-log-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none text-dark" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <section className="contact-section" id="contact">
        <div className="container">
          <div className="text-center" style={{ marginBottom: "5rem" }}>
            <p className="lead text-center">
              Have a question? Need advice? Contact ABFS today, we'd love to
              hear from you!
            </p>
          </div>

          {/* Business hours and phone */}
          <div className="mb-3">
            <a
              className="text-dark text-decoration-none"
              href="tel:305-251-4591"
              style={{ fontSize: "22px" }}
            >
              <FontAwesomeIcon icon={faPhone} className="mr-3" /> 305-251-4591
            </a>
          </div>
          <div className="mb-3">
            <a
              className="text-dark text-decoration-none "
              href="mailto:info@a-bfs.com"
              style={{ fontSize: "22px" }}
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-3" />{" "}
              info@a-bfs.com
            </a>
          </div>
          <div className="mb-3">
            <a
              style={{ fontSize: "22px" }}
              className="mb-5 text-dark text-decoration-none"
              href="https://www.google.com/maps/place/9032+SW+152nd+St,+Palmetto+Bay,+FL+33157/@25.628122,-80.340157,16z/data=!4m5!3m4!1s0x88d9c69e48ce485f:0x273d49912aab6e31!8m2!3d25.6281224!4d-80.3401572?hl=en"
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3" /> 9032 SW
              152 ST Miami, FL 33157
            </a>
          </div>
          {/* Google Maps */}
          <GoogleMap />

          {/* Contact From Section */}
          <hr></hr>
          <div className="my-5 text-center">
            <h4>Contact Us</h4>
            <small>Required fields *</small>
          </div>
          <Form />
        </div>
      </section>
    </div>
  )
}

export default Contact
