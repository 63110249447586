import React from "react"
import { Link } from "gatsby"
import SVGLogo from "../images/SVGLogo.svg"
import { Navbar, Nav, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

const NavBarStlyes = {
  position: "sticky",
}

const NavLinkStyles = {
  textDecoration: "none",
  color: "black",
}

const LogoStyles = {
  float: "left !important",
  height: 50,
}

const NavigationBar = () => {
  return (
    <Navbar bg="white" expand="lg" style={NavBarStlyes} fixed="top">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={SVGLogo}
              style={LogoStyles}
              alt="logo"
              className="img-responsive"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <a
              href="tel:305-251-4591"
              style={NavLinkStyles}
              className="nav-link"
            >
            <span className="font-weight-bold"><FontAwesomeIcon icon={faPhone} className="mr-2"/> 305.251.4591</span>
            </a>
            <Link
              to="/"
              style={NavLinkStyles}
              className="nav-link"
            >
              Home
            </Link>
            <Link
              to="/about"
              style={NavLinkStyles}
              className="nav-link"
            >
              About
            </Link>
            <Link
              to="/services"
              style={NavLinkStyles}
              className="nav-link"
            >
              Services
            </Link>
            <Link
              to="/contact"
              style={NavLinkStyles}
              className="nav-link"
            >
              Contact
            </Link>
            {/* <Link to="/blog" style={NavLinkStyles} className="nav-link">
              Blog
            </Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavigationBar
