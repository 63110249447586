import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalculator,
  faHandHoldingUsd,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons"

const Marketing = () => {
  return (
    <div className="bg-light shadow-sm" style={{ padding: "3rem" }}>
      <div className="container text-center text-dark">
        <h2>What We Do</h2>
        <hr className="mb-5 w-25"></hr>
        <p className="mb-5" style={{fontSize: "1.4rem"}}>
          At A. Bernard Financial Services we are committed to offering
          personalized assistance and strategies to get your business started
          along with a variety of other services.
        </p>
        <div className="row mb-5">
          <div className="col-lg-4 col-md-4 col-sm-4 mb-2">
            <FontAwesomeIcon icon={faCalculator} size="3x" />
            <h4 className="mt-4">Accounting & Bookkeeping</h4>
            <p>
              ABFS offers a variety of Bookkeeping & Accounting Services
              including; Financial statement services, Payroll Processing,
              Accounts payable and receivable, and more.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 mb-2">
            <FontAwesomeIcon icon={faHandHoldingUsd} size="3x" />
            <h4 className="mt-4">Tax Preparation</h4>
            <p>
              We provide full tax preparation services for individuals and
              businesses.
            </p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 mb-2">
            <FontAwesomeIcon icon={faHandshake} size="3x" />
            <h4 className="mt-4">Insurance Services</h4>
            <p>
              We work hand-in-hand with the highest-rated healthcare providers
              and other Insurance Companies to provide affordable plans in each
              state we are licensed in.
            </p>
          </div>
        </div>
        <Link to="/services" className="btn btn-outline-dark">
          Learn More
        </Link>
      </div>
    </div>
  )
}

export default Marketing
