import PropTypes from "prop-types"
import React from "react"
import NavigationBar from "./navbar"

const Header = () => (
  <>
   <NavigationBar />
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
