import React from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import Featurette from "./featurette"
import Marketing from "./marketing"
import buildinglookup from "../images/buildinglookup.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

const ContactButton = styled(GatsbyLink)`
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 50px;
`

const Home = () => {
  return (
    <div>
      {/* Company Banner Section */}
      <div
        style={{
          backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),url(${buildinglookup})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.45rem 1.0875rem`,
          }}
        >
          <h1
            style={{ marginTop: "2rem", textAlign: "center", color: `white` }}
          >
            A. Bernard Financial Services
          </h1>
          <p
            style={{
              marginTop: "3rem",
              textAlign: "center",
              color: `white`,
              fontSize: "30px",
            }}
          >
            FIND THE RIGHT PATH FOR YOUR BUSINESS
          </p>
          <p
            style={{
              marginTop: "2rem",
              textAlign: "center",
              color: `white`,
              fontSize: "x-large",
              fontStyle: "italic",
            }}
          >
            "Building Success through Customer Service"
          </p>
        </div>
      </div>

      {/* Home page marketing/services offered */}
      <Marketing />

      {/* Featurette */}
      <Featurette />

      {/* Contact information */}
      <div className="p-5 bg-light text-center">
        <h5>
          Our team of professionals is ready to help you.{" "}
          <a
            href="tel:305-251-4591"
            className="ml-1 text-decoration-none text-dark"
          >
            {" "}
            <FontAwesomeIcon icon={faPhone} /> Call (305) 251-4591.{" "}
          </a>{" "}
        </h5>
        <ContactButton to="/contact" className="btn btn-sm btn-dark shadow mt-4">
          CONTACT US
        </ContactButton>
      </div>
    </div>
  )
}

export default Home
