import React from "react"

const Footer = () => {
  return (
    <div style={{ background: "#3f7c36" }}>
      <footer className="text-light" style={{ padding: "1rem" }}>
        <div className="container">
          <div className="row">
            {/* <!-- Contact information --></div> */}
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h4 className="mb-4">Contact Information</h4>
              <p className=" mb-0">Phone: 305-251-4591</p>
              <p className=" mb-0"></p>
              <p className=" mb-0">
              <span>Email:</span> {""}
                <a
                  className="text-decoration-none text-light"
                  href="mailto:info@a-bfs.com"
                  target="noopener noreferrer"
                >
                  info@a-bfs.com
                </a>
              </p>
              <p className=" mb-0">
                9032 SW 152ND ST
                <br></br>
                Palmetto Bay, FL 33157
              </p>
            </div>

            {/* <!-- Footer Hours Text--> */}
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h4 className="mb-4">Hours</h4>
              <p className=" mb-0">Mon - Fri: 9 AM - 5 PM</p>
              <p className=" mb-0">Sat - Sun: By Appointment</p>
            </div>

            {/* <!-- Resources --></div> */}
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h4 className="mb-4">Tax Center</h4>
              <p className=" mb-0">
                <a
                  className="text-decoration-none text-light"
                  href="https://www.eftps.com/eftps/"
                  target="noopener noreferrer"
                >
                  Tax Payments
                </a>
              </p>
              <p className=" mb-0">
                <a
                  className="text-decoration-none text-light"
                  href="https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp"
                  target="noopener noreferrer"
                >
                  Refund Status
                </a>
              </p>
              <p className=" mb-0">
                <a
                  className="text-decoration-none text-light"
                  href="https://www.irs.gov/forms-instructions"
                  target="noopener noreferrer"
                >
                  Tax Forms
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className="text-center text-light p-2">
        Copyright © A. Bernard Financial Services {new Date().getFullYear()},
        All rights reserved.
      </div>
    </div>
  )
}

export default Footer
