import React from "react"

const Form = () => {
  return (
    <>
      <form
        id="contactForm"
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="row mb-5">
          <div className="col-md-6">
            <div className="form-group">
              <input
                className="form-control"
                id="name"
                name="name"
                type="text"
                placeholder="Your Name *"
                required
                data-validation-required-message="Please enter your name."
                aria-invalid="false"
              ></input>
              <p className="help-block text-danger"></p>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                id="email"
                name="email"
                type="email"
                placeholder="Your Email *"
                required
                data-validation-required-message="Please enter your email address."
              ></input>
              <p className="help-block text-danger"></p>
            </div>
            <div className="form-group mb-md-0">
              <input
                className="form-control"
                id="phone"
                name="phone"
                type="tel"
                placeholder="Your Phone *"
                required
                data-validation-required-message="Please enter your phone number."
              ></input>
              <p className="help-block text-danger"></p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group form-group-textarea mb-md-0">
              <textarea
                className="form-control"
                id="message"
                name="message"
                placeholder="Your Message *"
                required
                data-validation-required-message="Please enter a message."
                aria-invalid="false"
              ></textarea>
              <p className="help-block text-danger"></p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <div id="success"></div>
          <button
          className="btn btn-sm btn-dark shadow"
            id="sendMessageButton"
            type="submit"
          >
            Send Message
          </button>
        </div>
      </form>
    </>
  )
}

export default Form
