import React from "react"

const GoogleMap = () => {

  return (
    <div className="mb-5">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-2">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1875.024310477951!2d-80.34179577925468!3d25.628588440154086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9c69e48ce485f%3A0x273d49912aab6e31!2s9032%20SW%20152nd%20St%2C%20Palmetto%20Bay%2C%20FL%2033157!5e0!3m2!1sen!2sus!4v1613797985422!5m2!1sen!2sus"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="map"
          ></iframe> 
        </div>
      </div> 
    </div>
  )
}

export default GoogleMap
