import React from "react"
import Form from "./form"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import office from "../images/office.jpg"

const Services = () => {
  return (
    <div>
       <div
        style={{
          backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),url(${office})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "250px",
        }}
      >

        <div className="p-5">
          <h1
            style={{ marginTop: "3rem", textAlign: "center", color: `white` }}
          >
            Our Services
          </h1>
        </div>
      </div>

      <section
        className="p-5 text-light"
        style={{ backgroundColor: "#102E37" }}
      >
        <div className="container">
          <p className="lead">
            We're proud to offer a variety of business services that touch on
            every facet of your company. Our services are designed to help
            business owners and entrepreneurs reach their goals. Browse through
            our services below to learn how we'll help your strategy,
            operations, and more.
          </p>
        </div>
      </section>

      <section className="services-section" id="accounting">
        <div className="container">
          <div className="mb-5">
            {" "}
            <div className="">
              <div className="row ">
                <div className="col-log-4">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent">
                      <li className="breadcrumb-item">
                        <Link className="text-decoration-none text-dark" to="/">
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Services
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <h3 className="mb-3">ACCOUNTING & BOOKKEEPING SERVICES:</h3>
          <ul>
            <li className="mb-2">Accounts Payable and Receivable Processing</li>
            <li className="mb-2">Payroll Processing</li>
            <li className="mb-2">Preparation of Monthly Financial Reports</li>
            <li className="mb-2">Financial statement services</li>
            <li className="mb-2">Business incorporation services</li>
          </ul>
        </div>
      </section>

      <section className="services-section" id="taxes">
        <div className="container mt-5">
          <h3 className="mb-3">TAX SERVICES:</h3>
          <ul>
            <li className="mb-2">
              Strategic Tax Planning for Small Businesses as well as their
              Principals.
            </li>
            <li className="mb-2">
              Individual, Corporate, and Non Profit Tax Return Preparation.
            </li>
          </ul>
        </div>
      </section>

      <section className="services-section" id="insurance">
        <div className="container mt-5">
          <h3 className="mb-3">INSURANCE SERVICES:</h3>
          <ul>
            <li className="mb-2">Life Insurance</li>
            <li className="mb-2">Health Insurance</li>
            <li className="mb-2">OBAMACARE</li>
            <li className="mb-2">Annuities</li>
          </ul>
        </div>
        <div className="container">
        <a class="btn btn-dark rounded-pill" href="https://www.abfsinsurance.net" target="noopener noreferrer">Learn More</a>
        </div>
      </section>
      

      <section className="services-section" id="additional">
        <div className="container mt-5">
          <h3 className="mb-3">ADDITIONAL SERVICES:</h3>
          <ul>
            <li className="mb-2">
              Establishment of Profit and Non Profit Organizations
            </li>
            <li className="mb-2">Applications for 501c3</li>
            <li className="mb-2">Business Plans</li>
          </ul>
          <hr></hr>
        </div>
      </section>

      {/* Contact From Section */}
      <div className="container my-5">
        <div className="mb-5">
          <h4 className="mb-5 text-center">
            Call us today! We are here to help you.{" "}
            <a
              href="tel:305-251-4591"
              className="ml-1 text-decoration-none text-dark"
            >
              {" "}
              <FontAwesomeIcon icon={faPhone} /> Call (305) 251-4591.{" "}
            </a>{" "}
          </h4>
        </div>
        {/* Contact From Section */}
        <div className="mb-5 text-center">
            <h4>Contact Us</h4>
            <small>Required fields *</small>
          </div>
        <Form />
      </div>
    </div>
  )
}

export default Services
